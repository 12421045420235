import React, { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom';
import Switch from "../../utils/Switch";
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants from "../../utils/constants";
import { addEmployees } from "../../services/user-service";
import Swal from "sweetalert2";
import xclose from "../../assets/x-close.svg";
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";
import DropdownBox from "../common/DropDownBox";

const AddEditEmployeeMaster = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [submit, setSubmit] = useState(false);
  const [fieldCount, setFieldCount] = useState([]);
  const navigate = useNavigate();
  const data= props.roledata;
  let action = props.action;
 
  const handleSwitchChange = (e) => {
    if (action !== "View") {
      setProjectObj((prevState) => ({
        ...prevState,
        Status: e.target.checked ? "Active" : "Inactive",
      }));
    }
  };
  


useEffect(() => {
    let errempty=true
    for (let err in error) {          
      if( error[err] !==""){
        errempty=false
        break;
      }

    }
    if(errempty){
        setErrorMessage(); 
    }
   
}, [error])

  useEffect(() => {
    setError();
    setErrorMessage();
  }, [props.action]);

 

  const submitForm = (e) => {
    e.preventDefault();
    setSubmit(submit ? false : true);
  };

  useEffect(() => {
    const fieldValidation = 2;
    if (fieldCount.length >= fieldValidation) {
      setSubmit(submit ? false : true);
      handleSubmit();
      setFieldCount([]);
    }
  }, [fieldCount, action]);

  const validateDetails = () => {
    let tempError = false;
    for (let errorObj in error) {
      if (error[errorObj]) {
        tempError = true;
        break;
      }
    }

    if (tempError) {
      setErrorMessage("Please check above errors");
    } else {
      setErrorMessage("");
    }
    return tempError;
  };

  const handleSubmit = async () => {
    if (!validateDetails()) {
      let confirmationMessage =
        action === "Add"
          ? "Are you sure, do you want to Add New Employee?"
          : "Are you sure, do you want to Edit Employee?";
      let title =
        action === "Add"
          ? "Add New Employee"
          : "Edit Employee";
        
      MySwal.fire({
        title: title,
        html: confirmationMessage,
        ...constants.CONFIRMATION_BOX_CONFIG,
      }).then(async (selection) => {
        if (selection.isConfirmed) {
          setLoading(true);
          let result;
          if (action === "Add") {
            if (props.projectData.length > 0) {
              if (
                props.projectData.filter(
                  (item) =>
                    item.EmpEmail.toLowerCase() ===
                    projectObj.EmpEmail.toLowerCase()
                ).length > 0
              ) {
                setErrorMessage("Employee already exists.");
                setLoading(false);
                return;
              }
            }
          }
          delete projectObj.CreatedOnForDispaly;
          result = addEmployees(projectObj);
          result
            .then((resp) => {
              Alert("success", resp.Message);
              props.onHide();
              props.setReload();
              if (action === "Edit") {
                if (projectObj.Status === "Inactive") {
                if (projectObj.EmpEmail === sessionStorage.getItem("Email")){
                   navigate('/');
                   window.location.reload();                 
                }
              }
              }
            })
            .catch((err) => {
              setErrorMessage(err);
            });
          setLoading(false);
        }
      });
    } else {
      return;
    }
  };

  const [projectObj, setProjectObj] = useState(
    action === "Edit" || action === "View"
      ? props.rowdata
      : {
          EmpEmail: "",
          Status: "Active",
        }
  );

  const handleReset = () => {
    if (action === "Add") {
      setProjectObj((prevState) => ({
        ...prevState,
        EmpEmail: "",
        RoleCode: "",
        Region: "",
        LoginID: "",
        EmpName: "",
        Status: "Active",
      }));
    } else if (action === "Edit") {
      setProjectObj(props.rowdata);
    }
    
    setError();
    setErrorMessage();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if(name=="EmpEmail"){
      setProjectObj((prevState) => ({
        ...prevState,
        "LoginID": value?.split('@')[0],
      }));
      
    }
  };

  const commonProps = {
    setError: setError,
    error: error,
    submit: submit,
    setFieldCount: setFieldCount,
    fieldCount: fieldCount,
    onChange: handleChange,
  };
  console.log(props.rowdata);

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      <Modal {...props} size="lg" backdrop="static" className="ProjectModal">
        <Modal.Header>
          <Image src={xclose} onClick={props.onHide}></Image>
          <Modal.Title className="modal-title">{action} Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={4} md={12} className="txt-input">
              <Row>
                <Col className="status-switch">
                  <Form.Label className="fieldLabel gray-shade lblstatus">
                    Status
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className="sw-status">
                  <Switch
                    isOn={projectObj.Status == "Active" ? true : false}
                    handleToggle={(e) => {
                      handleSwitchChange(e);
                    }}
                  ></Switch>
                  <Form.Label className="fieldLabel">
                    {projectObj.Status}
                  </Form.Label>
                </Col>
              </Row>
            </Col>

            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Employee Name"
                maxLength={200}
                name="EmpName"
                value={projectObj.EmpName}
                required={true}
                isUserName={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Employee Email"
                maxLength={50}
                name="EmpEmail"
                value={projectObj.EmpEmail}
                required={true}
                isEmail={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Login ID"
                maxLength={50}
                name="LoginID"
                value={projectObj.LoginID}
                required={true}
                isAlphanumeric={true}
                readOnly={
                  true
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              {action === "Add" && (
                <>
                  <DropdownBox
                    label="Role Code"
                    name="RoleCode"
                    value={projectObj.RoleCode}
                    required={"true"}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Role Code</option>
                    {data
                      ? data.map((Type) => (
                          <option key={Type.RoleCode} value={Type.RoleCode}>
                            {Type.RoleCode}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
                </>
              )}
              {action === "Edit" && (
                <>
                  <DropdownBox
                    label="Role Code"
                    name="RoleCode"
                    value={projectObj.RoleCode}
                    required={"true"}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Role Code</option>
                    {data
                      ? data.map((Type) => (
                          <option key={Type.RoleCode} value={Type.RoleCode}>
                            {Type.RoleCode}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
                </>
              )}
              {action === "View" && (
                <>
                  <InputBox
                    type="text"
                    label="Role Code"
                    maxLength={50}
                    name="RoleCode"
                    value={projectObj.RoleCode}
                    required={true}
                    readOnly={action === "Edit" || "View" ? true : undefined}
                    {...commonProps}
                  />
                </>
              )}
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Region"
                maxLength={50}
                name="Region"
                value={projectObj.Region}
                required={true}
                isAlphanumeric={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {errorMessage && (
                <Form.Text className="text-danger">{errorMessage}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          {action !== "View" && (
            <Row>
              <div className="actionbtn">
                <Button
                  className="btn btn-outline-primary reset-button"
                  onClick={handleReset}
                  variant="outline-primary"
                >
                  Reset
                </Button>
                <Button
                  className="btn btn-primary semibold-togglebutton right-alignement btn-color"
                  type="submit"
                  onClick={submitForm}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
              </div>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEditEmployeeMaster;
