import React, { useState } from "react";
import DropFileInput from "../../utils/DropFileInput";
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants, { DISPATCHTYPE, NOTIFICATIONTYPE, ORDERSTATUS } from "../../utils/constants";

import Swal from "sweetalert2";
import xclose from "../../assets/x-close.svg";
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import { addBulkProject, addBulkHistoryProject, updateBulkProject } from "../../services/project-service";
import * as XLSX from 'xlsx';
import Validate from "../../utils/FileValidation";

const ExcelUploadProjectMaster = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const convertDateFormat = (inputDateString) => {
    const monFormatRegex = /^(\d{1}|0[1-9]|[1-2][0-9]|3[0-1])\/([A-Za-z]{3})\/(\d{4})$/;
    const monDashFormatRegex = /^(\d{1}|0[1-9]|[1-2][0-9]|3[0-1])-([A-Za-z]{3})-(\d{4})$/;
    const mmFormatRegex = /^(\d{1}|0[1-9]|[1-2][0-9]|3[0-1])\/(\d{2}|\d{1})\/(\d{4})$/;
    const mmDashFormatRegex = /^(\d{1}|0[1-9]|[1-2][0-9]|3[0-1])-(\d{2}|\d{1})-(\d{4})$/;

    const monMatches = inputDateString.match(monFormatRegex) || inputDateString.match(monDashFormatRegex);

    const dayMatchRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])$/;

    if (monMatches) {
      const day = dayMatchRegex.test(monMatches[1]) ? monMatches[1] : "0" + monMatches[1];
      const monthAbbreviation = dayMatchRegex.test(monMatches[2]) ? monMatches[2] : "0" + monMatches[2];
      const year = monMatches[3];

      const monthAbbreviations = {
        Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
        Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
      };

      const month = monthAbbreviations[monthAbbreviation];

      if (month) {
        const outputDateString = `${year}-${month}-${day}`;
        return outputDateString;
      }
    }

    const mmMatches = inputDateString.match(mmFormatRegex) || inputDateString.match(mmDashFormatRegex);

    if (mmMatches) {
      const day = dayMatchRegex.test(mmMatches[1]) ? mmMatches[1] : "0" + mmMatches[1];;
      const month = dayMatchRegex.test(mmMatches[2]) ? mmMatches[2] : "0" + mmMatches[2];
      const year = mmMatches[3];

      const outputDateString = `${year}-${month}-${day}`;
      return outputDateString;
    }

  };


  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiledu, setSelectedFiledu] = useState(null);

  const handleFileChange = (data) => {
    setSelectedFile(data);
  };

  const handleFileChangedu = (data) => {
    const { isValid, error } = Validate(data[0], []);
    if (!isValid) {
      setError((prev) => ({ ...prev, ["ProductImage"]: error }));
    }
    setSelectedFiledu(data);
  };


  const pattern = /^[^']{1,200}$/;
  const dateRegex = /^([1-9]|0[1-9]|[1-2][0-9]|3[0-1])[/-]([1-9]|0[1-9]|1[0-2]|[A-Za-z]{3})[/-]\d{4}$/;
 
  const decimalDigitRegex = /^[-+]?\d+(\.\d{1,2})?$/;

  const validateFields = (dataObject) => {
    const notUploadReason = [];
    if (!dataObject.project_no || dataObject.project_no === "" || !pattern.test(dataObject.project_no)) {
      notUploadReason.push(" Project No is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (!dataObject.dealer_name || dataObject.dealer_name === "" || !pattern.test(dataObject.dealer_name)) {
      notUploadReason.push(" Dealer Name is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (!dataObject.dealer_code || dataObject.dealer_code === "" || !pattern.test(dataObject.dealer_code)) {
      notUploadReason.push(" Dealer Code is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (!dataObject.order_type || dataObject.order_type === "" || !pattern.test(dataObject.order_type)) {
      notUploadReason.push(" Order Type is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (!dataObject.end_customer_name || dataObject.end_customer_name === "" || !pattern.test(dataObject.end_customer_name)) {
      notUploadReason.push(" End Customer Name is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (!dataObject.order_date || dataObject.order_date === "" || !dateRegex.test(dataObject.order_date)) {
      notUploadReason.push(" Order Date is mandatory and must be in format DD/MM/YYYY or DD/MON/YYYY")
    }
    if (!dataObject.product_group || dataObject.product_group === "" || !pattern.test(dataObject.product_group)) {
      notUploadReason.push(" Product Group is mandatory and should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (!dataObject.order_value || dataObject.order_value === "" || !decimalDigitRegex.test(dataObject.order_value)) {
      notUploadReason.push(" Order Value is mandatory and Order Value In LACS accepts only numbers upto two decimals")
    }
    if (!dataObject.branch || dataObject.branch === "" || !pattern.test(dataObject.branch)) {
      notUploadReason.push(" Branch is mandatory  and should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (dataObject.actual_dispatch_date !== "" && !dateRegex.test(dataObject.actual_dispatch_date) && dataObject.actual_dispatch_date) {
      notUploadReason.push(" Actual Dispatch Date must be in format DD/MM/YYYY or DD/MON/YYYY")
    }
    if (dataObject.planned_dispatch_date !== "" && !dateRegex.test(dataObject.planned_dispatch_date) && dataObject.planned_dispatch_date) {
      notUploadReason.push(" Planned Dispatch Date must be in format DD/MM/YYYY or DD/MON/YYYY")
    }
    if (dataObject.po_number !== "" && !pattern.test(dataObject.po_number) && dataObject.po_number) {
      notUploadReason.push(" Po number should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (dataObject.vehicle_consignment_number !== "" && !pattern.test(dataObject.vehicle_consignment_number) && dataObject.vehicle_consignment_number) {
      notUploadReason.push(" Vehicle / Consignment Number should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (dataObject.consignment_type !== "" && !pattern.test(dataObject.consignment_type) && dataObject.consignment_type) {
      notUploadReason.push(" Type of Consignment should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (dataObject.driver_number !== "" && !pattern.test(dataObject.driver_number) && dataObject.driver_number) {
      notUploadReason.push(" Driver Number should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if ((!dataObject.dispatch_type || dataObject.dispatch_type === "") || (dataObject.dispatch_type && dataObject.dispatch_type !== "" && !DISPATCHTYPE.filter(el => el === dataObject.dispatch_type.toUpperCase()).length > 0)) {
      notUploadReason.push(" PARTIAL / FULL / FINAL is Mandatory and accepts only : PARTIAL or FULL or FINAL")
    }
    if (dataObject.completed_status && dataObject.completed_status !== "" && !ORDERSTATUS.filter(el => el === dataObject.completed_status).length > 0) {
      notUploadReason.push(" Order Status accepts only : Order Booked, Planned Dispatch Date, Revision in Planned Dispatch Date, Order Dispatch, Delivered, Completed")
    }
    if (dataObject.notification_type && dataObject.notification_type !== "" && !NOTIFICATIONTYPE.filter(el => el === dataObject.notification_type).length > 0) {
      notUploadReason.push(" Notification Type accepts only : Delay, Prepone, OrderBooked")
    }
    if (dataObject.variable_1 && dataObject.variable_1 !== "" && !pattern.test(dataObject.variable_1)) {
      notUploadReason.push(" Generic Message  should not contain special characters Apostrophe (‘)  and character length is upto 200")
    }
    if (dataObject.variable_2 && dataObject.variable_2 !== "" && !pattern.test(dataObject.variable_2)) {
      notUploadReason.push(" Control variable  should not contain special characters Apostrophe (‘)  and character length is upto 200 ")
    }
    if (notUploadReason.length > 0) {
      return { validatestatus: false, validatereason: notUploadReason };
    }
    else {
      return { validatestatus: true, validatereason: "" };
    }

  };

  const validateRecords = async (mappedData) => {
    setLoading(true);
    const uploadReport = [];
    const insertedRecords = [];
    let addRecords = [];
    let addHistoryRecords = [];
    let updateRecords = [];
    let count = 0;

    for (const dataObject of mappedData) {
      setLoading(true);
      let date = new Date();
      count = count + 1;
      dataObject.planned_dispatch_date = new Date(dataObject.planned_dispatch_date) == "Invalid Date" ? dataObject.planned_dispatch_date : new Date(dataObject.planned_dispatch_date).getDate() + "/" + (new Date(dataObject.planned_dispatch_date).getMonth() + 1) + "/" + new Date(dataObject.planned_dispatch_date).getFullYear();
      dataObject.actual_dispatch_date = new Date(dataObject.actual_dispatch_date) == "Invalid Date" ? dataObject.actual_dispatch_date : new Date(dataObject.actual_dispatch_date).getDate() + "/" + (new Date(dataObject.actual_dispatch_date).getMonth() + 1) + "/" + new Date(dataObject.actual_dispatch_date).getFullYear();
      dataObject.order_date = new Date(dataObject.order_date) == "Invalid Date" ? dataObject.order_date : new Date(dataObject.order_date).getDate() + "/" + (new Date(dataObject.order_date).getMonth() + 1) + "/" + new Date(dataObject.order_date).getFullYear();
      const datainserted = insertedRecords.find((obj) => obj.project_no === dataObject.project_no);
      if (datainserted) {
        dataObject.Status = " Not Uploaded. Duplicate Record in excel "
        uploadReport.push(dataObject);
      } else {
        let validatedata = validateFields(dataObject)
        if (validatedata.validatestatus) {
          if (!dataObject.driver_number || dataObject.driver_number === "" || dataObject.driver_number === undefined) {
            dataObject.driver_number = ""
          }
          if (!dataObject.planned_dispatch_date || dataObject.planned_dispatch_date === "" || dataObject.planned_dispatch_date === undefined) {
            dataObject.planned_dispatch_date = ""
          }
          if (!dataObject.actual_dispatch_date || dataObject.actual_dispatch_date === "" || dataObject.actual_dispatch_date === undefined) {
            dataObject.actual_dispatch_date = ""
          }
          if (!dataObject.dispatch_type || dataObject.dispatch_type === "" || dataObject.dispatch_type === undefined) {
            dataObject.dispatch_type = ""
          }
          if (!dataObject.completed_status || dataObject.completed_status === "" || dataObject.completed_status === undefined) {
            dataObject.completed_status = ""
          }
          if (!dataObject.po_number || dataObject.po_number === "" || dataObject.po_number === undefined) {
            dataObject.po_number = ""
          }
          if (!dataObject.vehicle_consignment_number || dataObject.vehicle_consignment_number === "" || dataObject.vehicle_consignment_number === undefined) {
            dataObject.vehicle_consignment_number = ""
          }
          if (!dataObject.consignment_type || dataObject.consignment_type === "" || dataObject.consignment_type === undefined) {
            dataObject.consignment_type = ""
          }
          if (!dataObject.notification_status || dataObject.notification_status === "" || dataObject.notification_status === undefined) {
            dataObject.notification_status = ""
          }
          if (!dataObject.notification_type || dataObject.notification_type === "" || dataObject.notification_type === undefined) {
            dataObject.notification_type = ""
          }
          if (!dataObject.variable_1 || dataObject.variable_1 === "" || dataObject.variable_1 === undefined) {
            dataObject.variable_1 = ""
          }
          if (!dataObject.variable_2 || dataObject.variable_2 === "" || dataObject.variable_2 === undefined) {
            dataObject.variable_2 = ""
          }

          const found = props.data.findIndex(el => el.SK === "ProjectNo#" + dataObject.project_no);

          if (found !== -1) {
            let result;
            let cmpActulaDispatchDate = dataObject.actual_dispatch_date;
            if (!dataObject.actual_dispatch_date || convertDateFormat(dataObject.actual_dispatch_date.toString()) === undefined) {
              cmpActulaDispatchDate = "";
            }
            else {
              cmpActulaDispatchDate = convertDateFormat(dataObject.actual_dispatch_date.toString())
            }
            dataObject.revision_count = props.data[found].revision_count;
            dataObject.history_no = props.data[found].history_no;
            if (cmpActulaDispatchDate !== props.data[found].actual_dispatch_date.toString()) {
              dataObject.revision_count = props.data[found].revision_count + 1;
              dataObject.history_no = props.data[found].history_no + 1;
              let historyRecord = props.data[found];
              historyRecord.PK = "ProjectMasterMainHistory";
              historyRecord.SK = "ProjectNo#" + historyRecord.project_no + "#HistoryNo#" + historyRecord.history_no;;

              addHistoryRecords.push(props.data[found])
            }
            dataObject.CreatedBy = props.data[found].CreatedBy;
            dataObject.CreatedOn = props.data[found].CreatedOn;
            dataObject.CreatedById = props.data[found].CreatedById;
            dataObject.CreatedOnActual = props.data[found].CreatedOnActual;
            dataObject.dispatch_type = dataObject.dispatch_type.toUpperCase();
            dataObject.actual_dispatch_date = dataObject.actual_dispatch_date !== "" ? convertDateFormat(dataObject.actual_dispatch_date) : "";
            dataObject.planned_dispatch_date = dataObject.planned_dispatch_date !== "" ? convertDateFormat(dataObject.planned_dispatch_date) : "";
            dataObject.order_date = convertDateFormat(dataObject.order_date);
            updateRecords.push(dataObject);
            dataObject.Status = "Updated"
            insertedRecords.push(dataObject);
            uploadReport.push(dataObject);

          }
          else {
            let result;
            dataObject.order_type = dataObject.order_type.toUpperCase();
            dataObject.dealer_name = dataObject.dealer_name.toUpperCase();
            dataObject.end_customer_name = dataObject.end_customer_name.toUpperCase();
            dataObject.project_no = dataObject.project_no.toUpperCase();
            dataObject.product_group = dataObject.product_group.toUpperCase();
            dataObject.branch = dataObject.branch.toUpperCase();
            dataObject.dealer_code = dataObject.dealer_code.toUpperCase();
            dataObject.dispatch_type = dataObject.dispatch_type.toUpperCase();
            dataObject.revision_count = 0;
            dataObject.history_no = 0;
            dataObject.actual_dispatch_date = dataObject.actual_dispatch_date !== "" ? convertDateFormat(dataObject.actual_dispatch_date) : "";
            dataObject.planned_dispatch_date = dataObject.planned_dispatch_date !== "" ? convertDateFormat(dataObject.planned_dispatch_date) : "";
            dataObject.order_date = convertDateFormat(dataObject.order_date)
            dataObject.PK = "ProjectMasterMain";
            dataObject.SK = "ProjectNo#" + dataObject.project_no
            addRecords.push(dataObject);
            dataObject.Status = "Uploaded"
            insertedRecords.push(dataObject);
            uploadReport.push(dataObject);
          }

        }
        else {
          dataObject.Status = "Not Uploaded." + validatedata.validatereason;
          uploadReport.push(dataObject);
        }
      }
    }

    setLoading(false);
    return { uploadReport, addRecords, addHistoryRecords, updateRecords }
  }

  const handleUpload = async () => {
    try {
      if (selectedFile) {
        let title = "Upload Report"
        let confirmationMessage = "Are you sure, you want to upload report"
        MySwal.fire({
          title: title,
          html: confirmationMessage,
          ...constants.CONFIRMATION_BOX_CONFIG,
        }).then(async (selection) => {
          if (selection.isConfirmed) {
            setLoading(true);
            const reader = new FileReader();

            reader.onload = async (e) => {
              setLoading(true);
              const datas = new Uint8Array(e.target.result);
              const workbook = XLSX.read(datas, { type: 'array' });
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const excelData = XLSX.utils.sheet_to_json(worksheet, {
                header: 0,
                raw: false,
              });
              const expectedColumns = ['Type Of Orders', 'Dealer Name', 'Project Number', 'Dealer Code', 'End Customer Name', 'Order Booked Date', 'Product Group', 'Order Value In LACS', 'Branch'];

              const headerRow = excelData[0] || {};
              const missingColumns = expectedColumns.filter((col) => !headerRow.hasOwnProperty(col));


              if (missingColumns.length > 0) {
                alert(`Error in Report Upload because following columns in Report are missing : ${missingColumns}`)
             setLoading(false);
              } else {
                const mappedData = excelData.map((item) => ({
                  driver_number: item['Driver Number'],
                  dealer_name: item['Dealer Name'],
                  order_date: item['Order Booked Date'],
                  po_number: item['PO Number'],
                  consignment_type: item['Type of Consignment'],
                  notification_type: item['Notification Type'],
                  actual_dispatch_date: item['Actual Planned Dispatch Date'],
                  end_customer_name: item['End Customer Name'],
                  notification_status: item['Notification Status'],
                  branch: item['Branch'],
                  dispatch_type: item['Partial / Full / Final'],
                  dealer_code: item['Dealer Code'],
                  completed_status: item['Order Status'],
                  product_group: item['Product Group'],
                  order_type: item['Type Of Orders'],
                  planned_dispatch_date: item['Planned Dispatch Date'],
                  project_no: item['Project Number'],
                  vehicle_consignment_number: item['Vehicle / Consignment Number'],
                  order_value: item['Order Value In LACS'],
                  variable_1: item['Generic Message'],
                  variable_2: item['Control Variable'],
                }));
              
                setLoading(true);
                let validateRecordsData = await validateRecords(mappedData)
                console.log(" validateRecordsData ", validateRecordsData)
                let bulkupdateresult
                if (validateRecordsData.updateRecords.length > 0) {
                   setLoading(true);
                  bulkupdateresult = await updateBulkProject(validateRecordsData.updateRecords);
                  await new Promise(resolve => setTimeout(resolve, 6000));
                }
                console.log(" bulkupdateresult ", bulkupdateresult);
                createAndDownloadReport(validateRecordsData.uploadReport, 'ProjectMasterReport.xlsx');
                let bulkresult
                if (validateRecordsData.addRecords.length > 0) {
                   setLoading(true);
                  bulkresult = await addBulkProject(validateRecordsData.addRecords);
                 
                }
              
                console.log(" bulkresult ", bulkresult);
                let bulkhistoryresult
                if (validateRecordsData.addHistoryRecords.length > 0) {
                   setLoading(true);
                  bulkhistoryresult = await addBulkHistoryProject(validateRecordsData.addHistoryRecords);
                  await new Promise(resolve => setTimeout(resolve, 5000));
                }
                console.log(" validateRecordsData ", validateRecordsData);
                Promise.all([bulkresult, bulkupdateresult, bulkhistoryresult])
                  .then((responses) => {
                    console.log(" responses 111224 ");
                    console.log(responses);
                    setLoading(false);                   
                     props.onHideUpload();
                      props.setReload()
                    Alert("success", "Excel Records uploaded successfully");
                  })
                  .catch((error) => {
                    setLoading(false);
                    Alert("error", error);
                     props.onHideUpload();
                  });


              }
            };

            reader.readAsArrayBuffer(selectedFile);
            
               setLoading(false);

          }
        })
      }
    }
    catch (err) {
      console.log("Error in handleUpload ", err);
      setErrorMessage(" Error while uploading File");
      setLoading(false);
      props.onHideUpload();
    }
    finally {
      setLoading(false);
      
    }
  };



  function createAndDownloadReport(uploadReport, fileName) {
    const finalUploadReport = uploadReport.map((item) => ({
      'Type Of Orders': item.order_type,
      'Dealer Name': item.dealer_name,
      'Dealer Code': item.dealer_code,
      'End Customer Name': item.end_customer_name,
      'Order Booked Date': item.order_date,
      'Project Number': item.project_no,
      'Product Group': item.product_group,
      'Order Value In LACS': item.order_value,
      'Branch': item.branch,
      'Planned Dispatch Date': item.planned_dispatch_date,
      'Actual Planned Dispatch Date': item.actual_dispatch_date,
      'Partial / Full / Final': item.dispatch_type,
      'Order Status': item.completed_status,
      'PO Number': item.po_number,
      'Vehicle / Consignment Number': item.vehicle_consignment_number,
      'Type of Consignment': item.consignment_type,
      'Driver Number': item.driver_number,
      'Notification Status': item.notification_status,
      'Notification Type': item.notification_type,
      'Generic Message': item.variable_1,
      'Control Variable': item.variable_2,
      'Status': item.Status,
    }));
    const ws = XLSX.utils.json_to_sheet(finalUploadReport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  }

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      <Modal {...props} size="lg" backdrop="static" className="ProjectModal">
        <Modal.Header>
          <Image src={xclose} onClick={props.onHideUpload}></Image>
          <Modal.Title className="modal-title">Upload Projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <DropFileInput
              onFileChange={(file) => handleFileChangedu(file)}
              setError={setError}
              setFileData={handleFileChange}
              readOnly={false}
            />
          </Row>
          <Row>
            <Col>
              {error && error["ProductImage"] && (
                <Form.Text className="text-danger">{error["ProductImage"]}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          <Row>
            <Col>
              {errorMessage && (
                <Form.Text className="text-danger">{errorMessage}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          <Row>
            <div className="actionbtn">
              <Button
                className="btn btn-primary semibold-togglebutton right-alignement btn-color"
                type="submit"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </div>
          </Row>
          {/* )} */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExcelUploadProjectMaster;
