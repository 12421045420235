import axios from "axios";

const URL = process.env.REACT_APP_SSG_USER_API;
//broken authentication//
export function authenticate() {
  let url = URL + "/authenticate";
  return axios.get(url);
};

export async function getEmployee() {
  let url = URL + "/getAllEmployee";
  let response = await axios.get(url);
  return response.data
};


export const addEmployees = async (projectObj) => {
  let url = `${URL}/addEmployee`;
  const response = await axios.post(url, projectObj)
  return response.data;
};


export const deleteEmployee = async (emitem) => {
  let url = `${URL}/deleteEmployee`;
  const response = await axios.post(url, emitem)
  return response.data;
};